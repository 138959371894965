<template>
  <table class="table-credit-offered">
    <thead class="table-head">
      <tr class="table-row">
        <th class="table-row-item">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.material') }}
        </th>
        <th class="table-row-item">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.mass') }}
        </th>
        <th class="table-row-item">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.operator') }}
        </th>
        <th class="table-row-item">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.state') }}
        </th>
        <th class="table-row-item">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.credits') }}
        </th>
        <th class="table-row-item atribute-large">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.unit_value') }}
        </th>
        <th class="table-row-item atribute-large">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.central_custody_fee_value') }}
        </th>
        <th class="table-row-item atribute-large">
          {{ $t('components.card_credit_offered.table_credit_offereds.head.total_value') }}
        </th>
        <th class="table-row-item actions" />
      </tr>
    </thead>
    <tbody class="table-body">
      <tr
        v-for="item in offereds"
        :key="item.id"
        class="table-row"
      >
        <td class="table-row-item">
          <component :is="getIconByMaterialType(mountMaterial(item.material_type), false)" />
        </td>
        <td class="table-row-item">
          {{
            $t('components.card_credit_offered.table_credit_offereds.mass_quantity', {
              amount: item.mass_quantity.toLocaleString('pt-BR')
            })
          }}
        </td>
        <td class="table-row-item">
          {{ mountTranslate(`table_credit_offereds.businesses_kinds.${item.business_kind}`) }}
        </td>
        <td class="table-row-item">
          {{ item.business_state }}
        </td>
        <td class="table-row-item">
          {{
            $t('components.card_credit_offered.table_credit_offereds.credits_quantity', {
              amount: item.credits_quantity
            })
          }}
        </td>
        <td class="table-row-item atribute-large">
          {{ formatMoney(item.unit_value_with_aditional) }}
        </td>
        <td class="table-row-item atribute-large">
          {{ formatMoney(item.central_custody_fee_value) }}
        </td>
        <td class="table-row-item atribute-large">
          {{ formatMoney(item.total_value_with_aditional) }}
        </td>
        <td class="table-row-item actions">
          <span
            v-if="hasCartItem(item)"
            class="see-offer-button"
          >
            <v-icon
              color="primary"
              size="18"
            >
              mdi-check-circle
            </v-icon>
            {{ mountTranslate('table_credit_offereds.body.added_to_cart') }}
          </span>
          <rectoplus-button-outilined
            v-else
            class="add-cart-button"
            :text="mountTranslate('table_credit_offereds.body.text_button_add_card')"
            elevation="0"
            :outlined="true"
            @click="addOfferToCart(item)"
          />

          <span
            class="see-offer-button"
            @click="goToOffered(item.id)"
          >
            {{ mountTranslate('table_credit_offereds.body.text_button_see_offer') }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButtonOutilined from '@/components/buttons/RectoplusButtonOutlined.vue';
import getIconsByMaterialType from '@/mixins/getIconsOfMaterialType';
import formatMoney from '@/mixins/formatMoney';

export default {
  name: 'TableCreditOffered',
  components: {
    RectoplusButtonOutilined,
  },
  mixins: [getIconsByMaterialType, formatMoney],
  props: {
    offereds: {
      type: Array,
      default: () => [],
    },
  },
  computed: { ...mapGetters(['getCartItems']) },
  methods: {
    ...mapActions([
      'initLoading',
      'addToCart',
    ]),

    mountTranslate(value) {
      return this.$t(`components.card_credit_offered.${value}`);
    },

    goToOffered(id) {
      this.initLoading();
      this.$router.push({ name: 'offered', params: { id } });
    },

    addOfferToCart(offer) {
      this.addToCart({
        quantity: offer.credits_quantity,
        credit_offered_id: offer.id,
      });
    },

    hasCartItem(item) {
      const idOoffers = this.getCartItems.map((cartItem) => cartItem.credit_offered.id);

      return idOoffers.includes(item.id);
    },

    mountMaterial(material) {
      if (material === 'Papel') return 'paper';
      if (material === 'Plástico') return 'plastic';
      if (material === 'Vidro') return 'glass';
      return 'metal';
    },
  },
};
</script>
<style lang="scss" scoped>
.table-credit-offered {
  width: 100%;
  display: block;

  .table-head,
  .table-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $dashboard_background;
    border-radius: 6px;

    .table-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .table-row-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 0.875em;
        width: calc(200px - 100px);
        margin: 0;

        &.atribute-large {
          width: 150px;
        }

        &.actions {
          width: 30%;
        }
      }
    }
  }

  .table-body {
    background: $color_white;
    font-size: 0.875em;
    color: $color_gray;

    .table-row {
      border-radius: 6px;
      margin-top: 10px;
      background: white;

      .table-row-item {
        padding: 10px;

        &.atribute-large {
          width: 150px;
        }

        &.actions {
          flex-direction: column;

          .add-cart-button {
            margin: 0;
            width: 100%;
            max-width: 200px;
            height: 40px;
          }

          .see-offer-button {
            color: $color_primary;
            cursor: pointer;
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
