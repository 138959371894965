<template>
  <div class="filter-credit-offereds">
    <div class="filter-content">
      <div class="name-filter">
        {{ $t('components.filter_credit_offereds.title_filter_materials') }}
      </div>
      <div class="content-chip">
        <div
          v-for="(material, index) in filterMaterial"
          :key="index"
          :class="['chip-filter', chipSelected(material)]"
          @click="selectMaterial(index)"
        >
          {{ material.name }}
        </div>
      </div>
    </div>
    <div class="filter-content">
      <div class="name-filter">
        {{ $t('components.filter_credit_offereds.title_filter_logistic_operators') }}
      </div>
      <div class="content-chip">
        <div
          v-for="(logisticOperator, index) in filterLogisticOperator"
          :key="index"
          :class="[logisticOperatorsSelected(logisticOperator), 'chip-filter']"
          @click="selectLogisticOperator(index)"
        >
          {{ logisticOperator.name }}
        </div>
      </div>
    </div>
    <div class="filter-state">
      <div class="label">
        {{ $t('components.filter_credit_offereds.title_filter_states') }}
      </div>
      <select
        id="select-states"
        v-model="state"
        class="select-states"
        @change="selectState"
      >
        <option value="">
          {{ $t('components.filter_credit_offereds.select_states.all') }}
        </option>
        <option
          v-for="(stateAcronym, index) in states"
          :key="index"
          :value="stateAcronym"
        >
          {{ stateAcronym }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilterCreditOffereds',
  props: {
    states: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      state: null,
    };
  },
  computed: {
    ...mapGetters({
      filterState: 'getFilterByState',
      filterMaterial: 'getFilterMaterial',
      filterLogisticOperator: 'getFilterLogisticOperator',
    }),
  },
  mounted() {
    this.state = this.filterState;
  },
  methods: {
    ...mapActions({
      changeFilterMaterial: 'changeFilterMaterial',
      changeFilterLogisticOperator: 'changeFilterLogisticOperator',
      changeFilterByState: 'changeFilterByState',
    }),

    chipSelected(material) {
      return material.selected ? 'chip-selected' : '';
    },

    logisticOperatorsSelected(logisticOperator) {
      return logisticOperator.selected ? 'chip-selected' : '';
    },

    selectMaterial(index) {
      this.changeFilterMaterial(index);
      this.emitChange();
    },

    selectLogisticOperator(index) {
      this.changeFilterLogisticOperator(index);
      this.emitChange();
    },

    selectState() {
      this.changeFilterByState(this.state);
      this.emitChange();
    },

    emitChange() {
      this.$emit('change');
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-credit-offereds {
  display: flex;
  justify-content: space-between;

  .filter-material {
    .name-material {
      text-align: start;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .chip-material {
      display: flex;
      justify-content: space-between;
    }
  }
  .filter-logistic-operator {
    .name-operator {
      text-align: start;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .chip-operator {
      display: flex;
      justify-content: space-between;
    }
  }
  .filter-content {
    margin-right: 62px;

    .name-filter {
      text-align: start;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .content-chip {
      display: flex;
      justify-content: space-between;

      .chip-filter {
        background-color: transparent;
        border: $color_text_chip_filter solid 1px;
        border-radius: 24px;
        height: 30px;
        padding: 0 16px;
        font-size: 0.875em;
        font-weight: 600;
        display: flex;
        margin-right: 12px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color_text_chip_filter;

        &.chip-selected {
          background-color: $color_primary;
          border-color: $color_primary;
          color: white;
        }
      }
    }
  }

  .filter-state {
    .label {
      text-align: start;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .select-states {
      width: 100%;
      max-width: 300px;
      border: 2px solid $color_black;
      border-radius: 10px;
      padding: 2px 6px;
      height: 30px;
      font-weight: bold;
      font-size: 0.875em;

      background-image:
        linear-gradient(40deg, transparent 50%, $color_black 50%),
        linear-gradient(140deg, $color_black 50%, transparent 50%);
      background-position:
        calc(100% - 20px) calc(1em - 2px),
        calc(100% - 15px) calc(1em - 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;
      &:focus {
        background-image:
          linear-gradient(138deg, transparent 50%, $color_primary 50%),
          linear-gradient(38deg, $color_primary 50%, transparent 50%);
        outline: none;
        border-color: $color_primary;
      }
    }
  }
}
</style>
