<template>
  <div class="credit-offered">
    <div class="credit-offered-title">
      <back-button
        :text="$t('components.offered.button.back')"
        @click="goTohome()"
      />
      <span class="page">{{ $t('components.credit_offered.title') }}</span>
      <cart-button />
    </div>
    <card-credit-offered />
  </div>
</template>
<script>
import CardCreditOffered from '@/components/cards/home/CardCreditOffered.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import CartButton from '@/components/buttons/CartButton.vue';

export default {
  name: 'CreditOffered',
  components: {
    CardCreditOffered,
    BackButton,
    CartButton,
  },
};
</script>
<style lang="scss" scoped>
.credit-offered {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  padding: 2em 2em 2em 16em;
  flex-direction: column;

  .credit-offered-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 50px;

    .page {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
</style>
