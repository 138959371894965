<template>
  <div class="card-credit-offered">
    <div class="head-credit-offered">
      <div class="title">
        {{ $t('components.card_credit_offered.title_card') }}
      </div>
      <filter-credit-offereds
        :states="states"
        @change="fetchByFilters"
      />
    </div>
    <table-credit-offered
      :offereds="creditOffereds.data"
    />
    <rectoplus-pagination
      :size="pagination.size"
      :page="pagination.page"
      @changed="changePage"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import RectoplusPagination from '@/components/pagination/RectoplusPagination.vue';
import FilterCreditOffereds from '@/components/headers/credit_offered/FilterCreditOffereds.vue';
import TableCreditOffered from '@/components/tables/TableCreditOffered.vue';

export default {
  name: 'CardCreditOffered',
  components: {
    RectoplusPagination,
    FilterCreditOffereds,
    TableCreditOffered,
  },
  computed: {
    ...mapGetters({
      stateSelected: 'getFilterByState',
      logisticOperators: 'getFilterLogisticOperator',
      materials: 'getFilterMaterial',
      creditOffereds: 'getCreditOffereds',
      states: 'getBrazilianStates',
    }),
    pagination() {
      return {
        size: this.creditOffereds.pagination_settings?.pages,
        page: this.creditOffereds.pagination_settings?.page,
      };
    },
    filters() {
      return {
        by_material_type: this.materials.filter((option) => option.selected).map((option) => option.name).join(','),
        by_logistic_operator: this.logisticOperators.filter((option) => option.selected).map((option) => option.kind).join(','),
        by_state: this.stateSelected,
      };
    },
  },
  mounted() {
    this.searchState();
    this.fetchCreditOffereds({ ...this.filters, page: this.pagination.page });
  },
  methods: {
    ...mapActions([
      'searchState',
      'fetchCreditOffereds',
      'initLoading',
    ]),
    changePage(page) {
      this.initLoading();
      this.fetchCreditOffereds({ ...this.filters, page });
    },
    fetchByFilters() {
      this.initLoading();
      this.fetchCreditOffereds(this.filters);
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-credit-offered {
    margin-top: 32px;
    background: $color_white;
    border-radius: 10px;
    padding: 38px;
    padding-bottom: 50px;
    text-align: center;

    .head-credit-offered {
      margin-bottom: 48px;

      .title {
        text-align: start;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 1.2em;
      }
    }
  }
</style>
